<template>
	<b-container fluid class="px-1 pt-2 pb-3 message-box-row" :class="side">
		<b-row :align-h="side == 'left' ? 'start' : 'end'">
			<b-col cols="auto" class="message-box-container">
				<button v-if="object && object.type == 'add'" class="message-box add-message px-3 py-3" @click="openAddMenu" @blur="closeAddMenu" :class="side">
					<b-row align-v="center">
						<b-col class="message-box-message px-2 mx-2">
							<i class="fas fa-plus"></i>
							Adicionar mensagem
						</b-col>
					</b-row>
					<ContextMenu v-if="contextMenuAddActions" ref="add-menu" :actions="contextMenuAddActions" />
				</button>
				<b-container v-else class="message-box px-3 py-3" :class="side + (writing ? ' writing' : '')">
					<b-row align-v="center">
						<b-col class="message-box-message px-0 mx-2">
							<HtmlEditor v-if="changeMessage"
										v-model="vMessage"
										varsLabel="Dado do cliente"
										:varsList="completeVarsList"
										@focus="typeof editFunction === 'function' ? editFunction() : null"
										class="message-box-message-editor p-1" />
							<HtmlEditor v-else
										editable="false"
										v-model="vMessage"
										:varsList="completeVarsList" />
						</b-col>
						<b-col v-if="writing" cols="12" md="auto" class="pl-1">
							<i class="fas fa-check" title="Ok" @click="typeof closeFunction === 'function' ? closeFunction() : null"></i>
						</b-col>
						<b-col v-else-if="editFunction && !writing" cols="12" md="auto" class="pl-1">
							<i class="fas fa-pen" title="Editar" @click="typeof editFunction === 'function' ? editFunction() : null"></i>
						</b-col>
						<b-col v-if="!!deleteFunction" cols="12" md="auto" class="pl-1">
							<button class="message-delete-button p-0" @click="openDeleteMenu" @blur="closeDeleteMenu">
								<i class="fas fa-trash-alt" title="Excluir" @click="typeof deleteFunction === 'function' ? deleteFunction() : 0"></i>
								<ContextMenu v-if="contextMenuDeleteActions" ref="delete-menu" :actions="contextMenuDeleteActions" />
							</button>
						</b-col>
					</b-row>
				</b-container>
			</b-col>
		</b-row>
	</b-container>
</template>
<script>
	import HtmlEditor from "@/components/inputs/HtmlEditor";
	import ContextMenu from "@/components/menu-contexto/Default";
	import { ConstructorsNames } from "@/assets/js/models/arvore-decisoes";
	import ArvoreDecisoesProvider from "@/assets/js/providers/arvore-decisoes";

	export default {
		name: "MessageBox",
		components: {
			HtmlEditor,
			ContextMenu
		},
		inject: [
			"hasMessageList",
			"openMessageListSelector",
			"hasArchivedFlow",
			"openArchivedFlowSelector"
		],
		props: {
			message: null,
			side: {
				default: "left"
			},
			object: null,
			afterAddMessage: null,
			editFunction: null,
			closeFunction: null,
			deleteFunction: null,
			changeMessage: null,
			writing: false
		},
		data() {
			return {
				varsList: [
					{ value: "{CODIGO_ATENDIMENTO}", label: "Código do Atendimento" },
					{ value: "{CLIENTE_RAZAOSOCIAL_NOME}", label: "Razão Social / Nome" },
					{ value: "{CLIENTE_NOMEFANTASIA_APELIDO}", label: "Nome Fantasia / Apelido" },
					{ value: "{CLIENTE_CNPJ_CPF}", label: "CPF / CNPJ" },
					{ value: "{CLIENTE_TELEFONE}", label: "Telefone" },
					{ value: "{CLIENTE_EMAIL}", label: "E-mail" },
					{ value: "{CLIENTE_CEP}", label: "CEP" },
					{ value: "{CLIENTE_ENDERECO}", label: "Endereço" },
					{ value: "{CLIENTE_ENDERECO_NUMERO}", label: "Número" },
					{ value: "{CLIENTE_ENDERECO_COMPLEMENTO}", label: "Complemento" },
					{ value: "{CLIENTE_BAIRRO}", label: "Bairro" },
					{ value: "{CLIENTE_CIDADE}", label: "Cidade" },
					{ value: "{CLIENTE_ESTADO}", label: "Estado" }
				],
				contextMenuAddActions: null,
				contextMenuDeleteActions: null,
				submenuMessageList: null,
				clienteCamposComplementares: []
			}
		},
		methods: {
			openAddMenu() {
				this.contextMenuAddActions.refMessage.disabled = !this.hasMessageList();
				this.contextMenuAddActions.outMessage.disabled = !this.hasArchivedFlow();
				if (this.$refs["add-menu"]) this.$refs["add-menu"].show();
			},
			closeAddMenu() {
				if (this.$refs["add-menu"]) this.$refs["add-menu"].hide();
			},
			openDeleteMenu() {
				if (this.$refs["delete-menu"]) this.$refs["delete-menu"].show();
			},
			closeDeleteMenu() {
				if (this.$refs["delete-menu"]) this.$refs["delete-menu"].hide();
			}
		},
		computed: {
			vMessage: {
				get() {
					return this.message;
				},
				set(value) {
					this.changeMessage(value);
				}
			},
			completeVarsList() {
				return [
					...this.varsList,
					...this.clienteCamposComplementares,
					{ value: "{DADOS_TABULACAO}", label: "Dados Tabulação" }
				];
			}
		},
		mounted() {
			ArvoreDecisoesProvider.getClienteCamposComplementares().then(campos => {
				this.clienteCamposComplementares = campos.map(campo => ({ value: `{CLIENTE_CAMPO_COMPLEMENTAR_${campo.Id}}`, label: campo.Campo }));
			});
			if (this.object && this.object.type == "add") {
				let object = this.object.prevMessageObject,
                    proximaPergunta = object?.proximaPergunta;
				this.contextMenuAddActions = {
					newMessage: {
						label: "Criar nova mensagem",
						fasIcon: "fa-comment",
						action: () => {
							let newPergunta = (object.arvore ?? object.chat_pergunta?.arvore ?? object).createPergunta(object);
							if (typeof this.afterAddMessage == "function") setTimeout(() => this.afterAddMessage(newPergunta), 100);
						}
					},
					refMessage: {
						label: "Direcionar fluxo para...",
						title: `Escolha uma mensagem para ${proximaPergunta ? "substituir" : "direcionar"} o fluxo à partir deste ponto.`,
						fasIcon: "fa-stream",
						action: () => {
							this.openMessageListSelector(message => {
                                object.proxima_pergunta = message.id;
								if ((object.chat_pergunta?.arvore ?? object.arvore ?? object).id != "new") object.commitChanges();
							}, proximaPergunta, [object]);
						}
					},
					outMessage: {
						label: "Recuperar fluxo arquivado",
						fasIcon: "fa-archive",
						action: () => {
							this.openArchivedFlowSelector(message => {
								if (object.constructor.name == ConstructorsNames.ArvoreDecisoes) {
									object.pergunta_inicial = message.id;
									if (object.id != "new") object.commitChanges();
									return;
								}
								object.proxima_pergunta = message.id;
								if ((object.chat_pergunta?.arvore ?? object.arvore).id != "new") object.commitChanges();
							});
						}
					}
				};
			}
			if (this.deleteFunction) {
				this.contextMenuDeleteActions = {
					delMessage: {
						label: "Excluir mensagem",
						fasIcon: "fa-comment-slash",
						action: () => {
							this.deleteFunction("delMessage");
						}
					},
					archiveMessageFlow: {
						label: "Desvincular sequência neste ponto",
						fasIcon: "fa-unlink",
						action: () => {
							this.deleteFunction("archiveMessageFlow");
						}
					}
				};
			}
		}
	}
</script>
<style scoped>
	.message-box-container {
		max-width: 80%;
		word-break: break-word;
		font-size: 14px;
	}

	.message-box:before {
		content: "";
		position: absolute;
		top: 0;
		border-left: 20px transparent solid;
		border-right: 20px transparent solid;
		border-top: 20px transparent solid;
	}

	.message-box.left {
		background-color: var(--cinza-4);
	}

		.message-box.left:before {
			left: -5px;
			border-top-color: var(--cinza-4);
		}

	.message-box.right {
		background-color: var(--cor-primaria-cliente);
		color: #fff;
	}

		.message-box.right:before {
			right: -5px;
			border-top-color: var(--cor-primaria-cliente);
		}

	.message-box i.fas {
		cursor: pointer;
		font-size: 12px;
	}

	.message-box.writing {
		background-color: #faff8e;
	}

		.message-box.writing:before {
			border-top-color: #faff8e;
		}

	.message-box.add-message {
		background-color: var(--cinza-3);
		color: var(--cinza-5);
		cursor: pointer;
		border: 0;
		outline-style: none;
	}

		.message-box.add-message .fas.fa-plus {
			color: var(--cinza-5);
			margin-right: 3px;
			font-size: 14px;
		}

		.message-box.add-message:before {
			border-top-color: var(--cinza-3);
		}

		.message-box.add-message:hover, .message-box.add-message:focus {
			background-color: var(--cinza-4);
			color: #2c3e50;
		}

			.message-box.add-message:hover .fas.fa-plus, .message-box.add-message:focus .fas.fa-plus {
				color: var(--cor-primaria-cliente);
			}

			.message-box.add-message:hover:before, .message-box.add-message:focus:before {
				border-top-color: var(--cinza-4);
			}

	.message-box-message-editor {
		border: 1px transparent solid;
	}

		.message-box-message-editor:hover:not(:focus) {
			border: 1px var(--cinza-5) dotted;
		}

	.message-delete-button {
		background-color: transparent;
		border: 0;
		outline-style: none;
		position: relative;
	}
</style>
<style>
	.message-box-row div[contenteditable] .html-editor-variable {
		display: inline-flex;
		align-items: center;
		font-size: .8em;
		cursor: default;
	}

		.message-box-row div[contenteditable] .html-editor-variable:before {
			content: "\f1c0";
			font-family: "Font Awesome 5 Free";
			font-weight: 600;
			font-size: 1.2em;
			margin-right: 5px;
		}

	.message-box-row div[contenteditable] a .html-editor-variable {
		color: #007bff;
		border-color: #007bff;
	}

	.message-box-row.left div[contenteditable] .html-editor-variable {
		border: 1px var(--cor-primaria-cliente) solid;
		color: var(--cor-primaria-cliente);
		padding: 0 5px;
	}

	.message-box-row.right div[contenteditable] .html-editor-variable {
		border: 0;
		color: #fff;
		padding: 0;
	}
</style>