<template>
    <b-container>
        <b-row v-for="property in Object.keys(map == null ? {} : map)" v-if="!map[property].visible ? true : map[property].visible(object)" class="object-property">
            <div v-if="map[property].type == 'array'" class="w-100">
                <label>{{map[property].label}} ({{(!object[property] ? [] : object[property]).length}})</label>
                <div class="array-component app-scroll-custom">
                    <div v-for="item in map[property].list"
                         :disabled="object[`${property}Disabled`] && object[`${property}Disabled`].filter(_item => _item.value == item.value).length > 0"
                         :title="object[`${property}Disabled`] ? object[`${property}Disabled`].filter(_item => _item.value == item.value)[0]?.reason : ``">
                        <input type="checkbox" :id="propertyId + property + '-' + item.value" :value="item.value" v-model="object[property]" :disabled="object[`${property}Disabled`] && object[`${property}Disabled`].filter(_item => _item.value == item.value).length > 0" />
                        <label :for="propertyId + property + '-' + item.value" :class="item.class">{{item.label}}</label>
                    </div>
                </div>
            </div>
            <div v-else-if="map[property].type == 'matrix:collapsible'" class="w-100">
                <label>{{map[property].label}} ({{(!object[property] ? [] : object[property]).filter(obj => !obj._deleted).length}})</label>
                <div class="collapsible-component">
                    <div v-for="(item, index) in object[property]">
                        <div v-if="!item._deleted" class="collapsible-item">
                            <input type="checkbox" :id="propertyId + property + '-' + index" :name="propertyId + property" :value="index" @change="$event.target.checked ? openObject(property, index) : null" />
                            <label :for="propertyId + property + '-' + index" :title="map[property].model.title(item, index)">
                                <b-container>
                                    <b-row align-v="center">
                                        <b-col cols="12" md="auto" class="p-0">
                                            <i class="collapsible-icon fas"></i>
                                        </b-col>
                                        <b-col v-if="!!item._new" cols="12" md="auto" class="pl-2 pr-1">
                                            <span class="collapsible-new">NOVO</span>
                                        </b-col>
                                        <b-col class="px-2 text-truncate">
                                            {{map[property].model.title(item, index)}}
                                        </b-col>
                                        <b-col cols="12" md="auto" class="p-0">
                                            <i class="fas fa-trash mx-1" title="Remover" @click.stop.prevent="removeObject(item)"></i>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </label>
                            <div class="px-3 py-2">
                                <ObjectMapEditor :object="item" :map="map[property].model.properties" :idPrefix="property + '-' + index" />
                            </div>
                        </div>
                        <div v-else class="collapsible-deleted">
                            <b-container :title="'(Removido) ' + map[property].model.title(item, index)">
                                <b-row align-v="center">
                                    <b-col cols="6" md="auto" class="p-0">
                                        <i class="fas fa-times"></i>
                                    </b-col>
                                    <b-col class="collapsible-deleted-title px-2 text-truncate">
                                        {{map[property].model.title(item, index)}}
                                    </b-col>
                                    <b-col cols="6" md="auto" class="p-0">
                                        <i class="fas fa-undo mx-1" title="Restaurar" @click="restoreObject(item)"></i>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </div>
                    </div>
                    <div v-if="!!map[property].addable" class="collapsible-add">
                        <b-container>
                            <b-row align-v="center" class="justify-content-md-center" @click="addObject(property)">
                                <b-col cols="6" md="auto" class="px-1">
                                    <i class="fas fa-plus"></i>
                                </b-col>
                                <b-col cols="6" md="auto" class="px-1">Adicionar novo...</b-col>
                            </b-row>
                        </b-container>
                    </div>
                </div>
            </div>
            <div v-else-if="map[property].type.startsWith('span')" class="w-100">
                <label :for="propertyId + map[property].from.property">
                    {{map[property].label}}
                    <span v-if="map[property].type.split(':')[1] == 'date' && !someIsNullOrEmpty(object[map[property].from.property])">
                        <span v-if="someIsNullOrEmpty(object[map[property].to.property])">
                            (<i class="fas fa-infinity"></i> dias)
                        </span>
                        <span v-else>
                            ({{((new Date(object[map[property].to.property]) - new Date(object[map[property].from.property])) / 864e5) + 1}} dias)
                        </span>
                    </span>
                    <span v-else-if="map[property].type.split(':')[1] == 'time' && !someIsNullOrEmpty(object[map[property].from.property], object[map[property].to.property])">
                        ({{new Date("0001-01-01 " + object[map[property].to.property]).getHours() - new Date("0001-01-01 " + object[map[property].from.property]).getHours()}} horas)
                    </span>
                    <span v-else-if="map[property].type.split(':')[1] == 'number' && !!map[property].unit && !someIsNullOrEmpty(object[map[property].from.property], object[map[property].to.property])">
                        ({{object[map[property].to.property] - object[map[property].from.property] + 1}} {{map[property].unit}})
                    </span>
                </label>
                <b-container class="w-100">
                    <b-row align-v="center">
                        <b-col v-if="!someIsNullOrEmpty(map[property].from.label)" cols="6" md="auto" class="p-0 mr-2">
                            <label :for="propertyId + map[property].from.property">{{map[property].from.label}}</label>
                        </b-col>
                        <b-col class="p-0">
                            <input :id="propertyId + map[property].from.property" :type="map[property].type.split(':')[1]" class="w-100" v-model="object[map[property].from.property]" :max="object[map[property].to.property]" />
                        </b-col>
                        <b-col cols="6" md="auto" class="p-0 mx-2">
                            <label :for="propertyId + map[property].to.property">{{someIsNullOrEmpty(map[property].to.label) ? "—" : map[property].to.label}}</label>
                        </b-col>
                        <b-col class="p-0">
                            <input :id="propertyId + map[property].to.property" :type="map[property].type.split(':')[1]" class="w-100" v-model="object[map[property].to.property]" :min="object[map[property].from.property]" />
                        </b-col>
                    </b-row>
                </b-container>
            </div>
            <div v-else class="w-100">
                <label :for="propertyId + property" class="w-100 m-0">{{typeof map[property].label == 'function' ? map[property].label(object) : map[property].label }}</label>
                <select v-if="map[property].type == 'select'"
                        v-model="object[property]"
                        :id="propertyId + property"
                        :ref="object.getRefOf ? object.getRefOf(property) : null"
                        @change="commitChange"
                        class="w-100">
                    <option v-for="option in (typeof map[property].options == 'function' ? map[property].options(object) : map[property].options)" :value="option.value">{{option.text}}</option>
                </select>
                <select v-else-if="map[property].type == 'select.pair' && object[property]"
                        v-model="object[property][map[property].valueAt]"
                        :id="propertyId + property"
                        :ref="object.getRefOf ? object.getRefOf(property) : null"
                        @change="object[property][map[property].textAt] = $event.target.options[$event.target.selectedIndex].text"
                        class="w-100">
                    <option v-for="option in (typeof map[property].options == 'function' ? map[property].options(object) : map[property].options)" :value="option.value">{{option.text}}</option>
                </select>
                <HtmlEditor v-else-if="map[property].type == 'html'"
                            v-model="object[property]"
                            :id="propertyId + property"
                            :ref="object.getRefOf ? object.getRefOf(property) : null"
                            :varsList="map[property].varsList"
                            class="html-editor p-1" />
                <input v-else
                       v-model="object[property]"
                       :id="propertyId + property"
                       :type="map[property].type"
                       :ref="object.getRefOf ? object.getRefOf(property) : null"
                       :autocomplete="property"
                       class="w-100" />
            </div>
        </b-row>
    </b-container>
</template>
<script>
    import HtmlEditor from "@/components/inputs/HtmlEditor";
    export default {
        name: "ObjectMapEditor",
        components: {
            HtmlEditor
        },
        props: {
            object: null,
            map: null,
            idPrefix: null
        },
        computed: {
            propertyId() {
                return `property-${!this.idPrefix ? "" : `${this.idPrefix}-`}`;
            }
        },
        methods: {
            commitChange() {
                if (this.object.tipo === 4 && this.object.tabulacao && this.object.tabulacao.Tipo >= 3) {
                    this.object.addOpcoesTabulacaoItens();
                }
            },
            someIsNullOrEmpty(...value) {
                return value.filter(val => (val ?? "").trim().length == 0).length > 0;
            },
            addObject(property) {
                if (this.object[property] == undefined) this.$set(this.object, property, []);
                this.object[property].push((_ => {
                    return Object.assign(...Object.keys(_).map(prop => ({
                        [prop]: _[prop].default
                    })), { _new: true });
                })(this.map[property].model.properties));
                this.openObject(property, this.object[property].length - 1)
            },
            removeObject(obj) {
                this.$set(obj, "_deleted", true);
            },
            restoreObject(obj) {
                this.$delete(obj, "_deleted");
            },
            async openObject(property, value) {
                await this.$nextTick();
                (_ => {
                    _.prop("checked", true);
                    _.next().next().find("input:not([type=checkbox], [type=radio]), select").first().focus();
                    _.parents(".app-scroll-custom").get(0)
                        .scrollTo({ top: _.parent().get(0).offsetTop - 50, behavior: "smooth" });
                })($(`input[type=checkbox][name="${this.propertyId + property}"][value=${value}]`));
            },
        }
    }
</script>
<style scoped>
    .object-property {
        margin-bottom: 10px;
    }

        .object-property label {
            display: block;
            color: var(--cinza-5);
            font-weight: bold;
            font-size: 12px;
            margin-bottom: 0;
        }

    input, select, .html-editor {
        border: 1px var(--cinza-4) solid;
        background-color: var(--cinza-2);
        font-size: 14px;
        padding: 5px 7px;
        color: var(--cinza-5);
        outline-style: none;
        transition: all ease-in-out .3s;
    }

        input:focus, select:focus, .html-editor:focus-within {
            filter: brightness(1.03);
        }

    .array-component {
        overflow-y: auto;
        max-height: 250px;
        background-color: var(--cinza-2);
        border: 1px var(--cinza-4) solid;
    }

        .array-component > div[disabled] > label {
            background-color: var(--cinza-1);
            color: var(--cinza-4);
            cursor: default;
        }

        .array-component input[type=checkbox] {
            display: none;
        }

        .array-component label {
            cursor: pointer;
            padding: 5px 7px;
            margin: 0;
            transition: all ease-in-out .3s;
        }

            .array-component label:hover {
                background-color: #fff;
            }

            .array-component label:before {
                content: "\f0c8";
                font-family: "Font Awesome 5 Free";
                font-weight: 500;
                margin-right: 10px;
            }

        .array-component input[type=checkbox]:checked + label {
            background-color: var(--cinza-3);
        }

            .array-component input[type=checkbox]:checked + label:before {
                content: "\f14a";
                font-weight: 600;
            }

    .collapsible-component {
        background-color: var(--cinza-2);
        border: 1px var(--cinza-4) solid;
    }

    .collapsible-item > input[type=checkbox] {
        display: none;
    }

    .collapsible-item > label {
        padding: 5px 7px;
        cursor: pointer;
        transition: all ease-in-out.3s;
    }

        .collapsible-item > label .collapsible-new {
            color: var(--cor-primaria-cliente);
            font-size: 9px;
        }

        .collapsible-item > label i.collapsible-icon {
            width: 8px;
            text-align: center;
        }

            .collapsible-item > label i.collapsible-icon:before {
                content: "\f105";
            }

        .collapsible-item > label i.fa-trash {
            display: none;
            font-size: 10px;
            opacity: .9;
            transition: all ease-in-out .3s;
        }

            .collapsible-item > label i.fa-trash:hover {
                opacity: 1;
                color: #c54444;
            }

        .collapsible-item > label:hover {
            background-color: #fff;
        }

            .collapsible-item > label:hover i.fa-trash {
                display: block;
            }

        .collapsible-item > label + div {
            display: none;
            box-sizing: border-box;
            overflow: hidden;
            background-color: #fff;
            border-bottom: 1px var(--cinza-4) solid;
        }

    .collapsible-item > input[type=checkbox]:checked + label {
        background-color: var(--cinza-3);
    }

        .collapsible-item > input[type=checkbox]:checked + label i.collapsible-icon:before {
            content: "\f107";
        }

        .collapsible-item > input[type=checkbox]:checked + label i.fa-trash {
            display: block;
        }

        .collapsible-item > input[type=checkbox]:checked + label + div {
            display: block;
        }

    .collapsible-deleted {
        padding: 5px 7px;
        background-color: var(--cinza-1);
        color: var(--cinza-4);
        font-weight: bold;
        font-size: 12px;
    }

        .collapsible-deleted i.fas {
            font-size: 10px;
        }

        .collapsible-deleted .collapsible-deleted-title {
            text-decoration: line-through;
        }

        .collapsible-deleted i.fa-undo {
            cursor: pointer;
            transition: color ease-in-out .3s;
        }

            .collapsible-deleted i.fa-undo:hover {
                color: #0a68eb;
            }

    .collapsible-add {
        padding: 5px 7px;
        cursor: pointer;
        background-color: #fff;
        color: var(--cor-primaria-cliente);
        font-weight: bold;
        font-size: 12px;
        cursor: pointer;
    }

        .collapsible-add:hover {
            filter: brightness(1.1)
        }
</style>
<style>
    .html-editor div[contenteditable] .html-editor-variable {
        display: inline-flex;
        align-items: center;
        font-size: .8em;
        cursor: default;
        border: 1px var(--cor-primaria-cliente) solid;
        color: var(--cor-primaria-cliente);
        padding: 0 5px;
    }

        .html-editor div[contenteditable] .html-editor-variable:before {
            content: "\f1c0";
            font-family: "Font Awesome 5 Free";
            font-weight: 600;
            font-size: 1.2em;
            margin-right: 5px;
        }
</style>