<template>
	<b-modal id="archived-flow-selector" title="Selecione um fluxo..." size="md" @hide="reset">
		<b-container class="app-scroll-custom">
			<b-row v-for="message in flowList"
				   :class="`archived-flow mb-2${selectedFlow == message ? ' selected' : ''}`"
				   @click="selectedFlow = message">
				<b-col cols="12" md="auto" class="px-2 py-1">
					<i class="fas fa-sitemap"></i>
				</b-col>
				<b-col class="px-2 py-1" v-html="message.descricao"></b-col>
			</b-row>
		</b-container>
		<template #modal-footer="{ cancel }">
			<b-button variant="light" @click="cancel()" class="rounded-0">Cancelar</b-button>
			<b-button variant="success"
					  @click="confirm"
					  class="rounded-0"
					  :disabled="!selectedFlow">
				Confirmar
			</b-button>
		</template>
	</b-modal>
</template>
<script>
	export default {
		name: "ModalArchivedFlowSelector",
		props: {
			getArchivedFlowList: null
		},
		data() {
			return {
				flowList: null,
				selectedFlow: null,
				originalSelection: null,
				onSelect: null
			}
		},
		methods: {
			open(onSelect, originalSelection) {
				if (typeof this.getArchivedFlowList != "function") return;
				this.flowList = this.getArchivedFlowList();
				this.onSelect = onSelect;
				this.originalSelection = originalSelection;
				this.$bvModal.show("archived-flow-selector");
			},
			confirm() {
				let changeFlow = () => {
					if (typeof this.onSelect == "function") {
						this.onSelect(this.selectedFlow);
						this.$bvModal.hide("archived-flow-selector");
					}
				};
				if (this.originalSelection) {
					this.$bvModal.msgBoxConfirm("O fluxo atual será substituído pelo fluxo arquivado selecionado.", {
						title: "Atenção",
						size: "md",
						buttonSize: "sm",
						okVariant: "danger",
						okTitle: "Entendi, confirmar substituição",
						cancelTitle: "Cancelar"
					}).then(confirm => {
						if (confirm) changeFlow();
					});
					return;
				} else changeFlow();
			},
			reset() {
				setTimeout(() => {
					this.flowList = null;
					this.selectedFlow = null;
					this.onSelect = null;
				}, 300);
			}
		}
	}
</script>
<style scoped>
	.archived-flow {
		background-color: var(--cinza-3);
		border: 1px var(--cinza-4) solid;
		cursor: pointer;
	}

		.archived-flow.selected {
			background-color: var(--cor-primaria-cliente);
			border-color: var(--cor-primaria-cliente);
			color: #fff;
			cursor: default;
		}
</style>