import Vue from "vue";
import ArvoreDecisoesServices from "@/assets/js/services/ArvoreDecisoesServices";
import ArvoreDecisoesProvider from "@/assets/js/providers/arvore-decisoes";

class ArvoreDecisoes extends Vue {
    constructor(obj) {
        super({
            data: {
                id: null,
                nome: null,
                pergunta_inicial: null,
                canais: [],
                canaisDisabled: [],
                perguntas: [],
                messageFlow: null,
                changes: []
            },
            watch: {
                pergunta_inicial() {
                    this.messageFlow = [];
                    if (!this.pergunta_inicial) {
                        this.messageFlow.push(new MessageAdd(this));
                        return;
                    }
                    setTimeout(() => {
                        this.addMessageFlowFrom(this.perguntaInicial);
                    }, 500);
                }
            }
        });
        if (obj == null) return;
        Object.keys(this).forEach(key => {
            if (obj[key] == null) return;
            if (key == "perguntas") {
                this.perguntas = obj[key].map(pergunta => new ChatPergunta(pergunta));
                return;
            }
            this[key] = obj[key];
        });
    }
    createPergunta(afterObject) {
        let pergunta = new ChatPergunta({
                id: Symbol(),
                arvore: this,
                descricao: "Digite aqui o texto da mensagem...",
                tipo: 0,
                proxima_pergunta: afterObject instanceof ArvoreDecisoes ? null : afterObject.proxima_pergunta
            }),
            linkPergunta = () => {
                this.perguntas.push(pergunta);
                if (afterObject instanceof ArvoreDecisoes) {
                    this.pergunta_inicial = pergunta.id;
                    if (this.id != "new") this.commitChanges();
                    return pergunta;
                }
                afterObject.proxima_pergunta = pergunta.id;
                if (this.id != "new") afterObject.commitChanges();
                this.reportChangeOf(afterObject, afterObject instanceof ChatPergunta ? "tipo" : "intencao");
            };
        if (this.id != "new") {
            pergunta.commitChanges().then(linkPergunta);
            return pergunta;
        }
        linkPergunta();
        return pergunta;
    }
    get perguntaInicial() {
        return this.getPergunta(this.pergunta_inicial);
    }
    getPerguntasAusentes() {
        let perguntasSequenciadas = new Set(Array.prototype.concat.apply([], this.perguntas.map(
            pergunta => [pergunta.proxima_pergunta].concat(pergunta.opcoes.map(opcao => opcao.proxima_pergunta))
        )).filter(pergunta => pergunta));
        return this.perguntas.filter(pergunta => !perguntasSequenciadas.has(pergunta.id) && pergunta.id != this.pergunta_inicial);
    }
    getPergunta(id) {
        return this.perguntas.filter(pergunta => pergunta.id == id)[0];
    }
    getFluxoDePerguntas(pergunta, perguntasAdicionadas = []) {
        if (!pergunta) return [];
        if (perguntasAdicionadas.includes(pergunta)) return [];
        perguntasAdicionadas.push(pergunta);
        let proximo = [];
        if (pergunta.tipo == 0 || (pergunta.tipo == 1 && pergunta.dado_modo_entrada == "simples")) proximo = pergunta.proximaPergunta ?? [];
        else if (pergunta.tipo == 4 && pergunta.tabulacao.Tipo < 3) proximo = pergunta.proximaPergunta;
        else if (pergunta.tipo == 2 || (pergunta.tipo == 4 && pergunta.tabulacao.Tipo >= 3) || (pergunta.tipo == 1 && pergunta.dado_modo_entrada == "ramificado")) {
            pergunta.opcoes.forEach(opcao => {
                if (opcao.proximaPergunta && !perguntasAdicionadas.includes(opcao.proximaPergunta)) proximo.push({
                    opcao: opcao.descricao,
                    getPerguntas: () => this.getFluxoDePerguntas(opcao.proximaPergunta, [...perguntasAdicionadas])
                });
            });
        }
        return [pergunta, ...(proximo instanceof ChatPergunta ? this.getFluxoDePerguntas(proximo, perguntasAdicionadas) : proximo)];
    }
    getUpdatedData() {
        if (!this.id) throw "Não é possível obter dados de um `id` nulo.";
        if (this.id == "new") {
            let messageAdd = new MessageAdd(this);
            messageAdd.visible = true;
            this.messageFlow = [messageAdd];
            return Promise.resolve();
        }
        return ArvoreDecisoesServices.get(this.id).then(data => {
            this.nome = data.nome;
            this.canais = data.canais;
            this.canaisDisabled = data.canaisDisabled;
            this.perguntas = (data.perguntas ?? []).map(pergunta => new ChatPergunta({
                ...pergunta,
                tipo: pergunta.tipo == 0 && pergunta.setor_redirecionamento ? "transbordo" : pergunta.tipo,
                descricao: pergunta.descricao.replaceAll("<p>", "<span>").replaceAll("</p>", "</span><br />"),
                opcoes: (opcoes => {
                    if (pergunta.tipo == 2 || pergunta.tipo == 1) return opcoes;
                    if ((pergunta.tipo == 4 && pergunta.tabulacao.Tipo >= 3)) return opcoes.filter(opcao => pergunta.tabulacao.TabulacoesItens.find(tabulacaoItem => tabulacaoItem.id == opcao.TabulacaoItemId));
                    return [];
                })(pergunta.opcoes),
                tabulacao: pergunta.tipo == 4 ? pergunta.tabulacao : null,
                dado: pergunta.tipo == 1 ? pergunta.dado : null,
                arvore: this
            }));
            this.pergunta_inicial = data.pergunta_inicial;
        });
    }
    reset() {
        this.pergunta_inicial = null;
        this.canais = [];
        this.perguntas = [];
        this.messageFlow = null;
    }
    addMessageFlowFrom(obj) {
        if (!obj) return;
        if (obj instanceof ChatPerguntaOpcao && obj.proximaPergunta) obj = obj.proximaPergunta;
        if (obj instanceof ChatPergunta && this.messageFlow.map(item => item.object).includes(obj)) {
            let object = this.messageFlow.filter(item => item.object instanceof ChatPergunta).slice(-1)[0].object;
            if (!object.proximaPergunta) object = object.opcoes.filter(item => item.proximaPergunta == obj)[0];
            this.messageFlow = [
                ...this.messageFlow,
                new Message({
                    type: "log-loop",
                    object: object,
                    target: obj,
                    content: "Retorno de fluxo",
                    icon: "fa-arrow-up"
                })];
            return;
        }
        this.messageFlow = [...this.messageFlow, ...obj.message];
        this.addMessageFlowFrom(obj.proximaPergunta);
    }
    reportChangeOf(obj, change) {
        if (!obj) return;
        if (obj instanceof ChatPergunta) {
            let messages = this.messageFlow.map((message, index) => ({ index: index, message: message })).filter(item => item.message.object == obj && ["message", "options"].includes(item.message.type));
            if (messages.length == 0) return;
            switch (change) {
                default:
                    this.messageFlow.splice(messages[0].index, this.messageFlow.length);
                    this.addMessageFlowFrom(obj);
                    break;
                case "descricao":
                    messages.forEach(item => {
                        item.message.content = obj.descricao;
                    });
                    break;
                case "tipo":
                    {
                        let item = messages[0],
                            object = item.message.object,
                            refreshItem = {
                                0: () => {
                                    object.dado = null;
                                    object.proxima_pergunta = object.proxima_pergunta ?? (object.opcoes.length == 1 ? object.opcoes[0].proxima_pergunta : null);
                                    object.setor_redirecionamento = null;
                                    object.opcoesOcultas = [...object.opcoesOcultas, ...object.opcoes];
                                    object.opcoes = [];
                                    object.tabulacao = null;
                                },
                                1: () => {
                                    object.dado = object.dado ?? 1;
                                    object.proxima_pergunta = object.proxima_pergunta ?? (object.opcoes.length == 1 ? object.opcoes[0].proxima_pergunta : null);
                                    object.opcoesOcultas = [
                                        ...object.opcoesOcultas,
                                        ...object.opcoes
                                    ];
                                    object.opcoes = [];
                                    object.tabulacao = null;
                                    object.setor_redirecionamento = null;
                                },
                                2: () => {
                                    if (object.opcoes.length > 0) object.opcoesOcultas = [...object.opcoesOcultas, ...object.opcoes];
                                    object.opcoes = object.opcoesOcultas.filter(opcao => opcao.TabulacaoItemId == null && !opcao.ClientesComplementoOpcaoId);
                                    object.opcoesOcultas = object.opcoesOcultas.filter(item => object.opcoes.indexOf(item) == -1);
                                    object.dado = null;
                                    object.tabulacao = null;
                                    object.proxima_pergunta = null;
                                    object.setor_redirecionamento = null;
                                },
                                transbordo: () => {
                                    if (object.setor_redirecionamento == null) object.setor_redirecionamento = {
                                        id: null,
                                        nome: "..."
                                    };
                                    object.dado = null;
                                    object.opcoesOcultas = [...object.opcoesOcultas, ...object.opcoes];
                                    object.tabulacao = null;
                                    object.proxima_pergunta = null;
                                    object.opcoes = [];
                                },
                                3: () => {
                                    object.dado = null;
                                    object.opcoesOcultas = [...object.opcoesOcultas, ...object.opcoes];
                                    object.tabulacao = null;
                                    object.proxima_pergunta = null;
                                    object.setor_redirecionamento = null;
                                    object.opcoes = [];
                                },
                                4: () => {
                                    object.tabulacao = object.tabulacao ?? 0;
                                    object.dado = null;
                                    if (object.opcoes.length > 0) object.opcoesOcultas = [...object.opcoesOcultas, ...object.opcoes];
                                    object.opcoes = object.opcoes.filter(opcao => opcao.TabulacaoItemId != null);

                                    object.opcoesOcultas = object.opcoesOcultas.filter(item => object.opcoes.indexOf(item) == -1);

                                    object.proxima_pergunta = object.proxima_pergunta ?? (object.opcoes.length == 1 ? object.opcoes[0].proxima_pergunta : null);
                                    object.setor_redirecionamento = null;
                                },
                            };
                        refreshItem[object.tipo]();
                        this.messageFlow.splice(item.index, this.messageFlow.length);
                        this.addMessageFlowFrom(object);
                    }
                    break;
                case "tabulacao":
                    if ((obj.tabulacao && obj.tabulacao?.Tipo < 3)) {
                        obj.opcoes = [];
                        messages.filter(item => item.message.object.tipo == 4).forEach(item => {
                            this.messageFlow.splice(item.index + 1, 1, item.message.object.message[1]);
                            if (item.message.type == "options") this.messageFlow.splice(item.index + 1, 1, item.message.object.message[2]);
                        });
                        break;
                    }
                    messages.filter(item => item.message.object.tipo == 4 && (item.message.type == "options" || item.message.object.tabulacao.Tipo >= 3)).forEach(item => {
                        if (item.message.type == "options") this.messageFlow.splice(item.index, 1, item.message.object.message[1]);
                        else {
                            this.messageFlow[item.index].object.proxima_pergunta = null;
                            this.messageFlow.splice(item.index + 1, 1, item.message.object.message[1]);
                            if (!item.message.object.message[2]) this.messageFlow.splice(item.index + 2, 1);
                            if (this.messageFlow[item.index + 2]?.type == "add") this.messageFlow.splice(item.index + 2, 1);
                        }
                        if (item.message.object.message[2]) this.messageFlow.splice(item.index + 1, 1, item.message.object.message[2]);
                    });
                    break;
                case "dado":
                    obj.opcoesOcultas = [...obj.opcoesOcultas, ...obj.opcoes.filter(opcao => !opcao.ClientesComplementoOpcaoId)];
                    obj.opcoes.filter(opcao => !!opcao.ClientesComplementoOpcaoId).forEach(opcao => opcao.delete());
                    obj.opcoes = [];
                    obj.dado_modo_entrada = "simples";
                    this.messageFlow.splice(messages[0].index, this.messageFlow.length);
                    this.addMessageFlowFrom(obj);
                    break;
                case "dado_modo_entrada":
                    if (obj.dado_modo_entrada == "simples" && obj.opcoes.length > 0) {
						this.$bvModal.msgBoxConfirm([
							this.$createElement("div", {
								domProps: {
									innerHTML: `Há ramificações configuradas que serão perdidas caso você altere o modo de entrada de dados para <b>simples</b>.<br />Os fluxos existentes serão arquivados e poderão ser recuperados.<br /><br />Deseja continuar?`
								}
							})
						], {
							title: "Confirmação",
							size: "md",
							buttonSize: "sm",
							okVariant: "success",
							okTitle: "Sim",
							cancelTitle: "Não"
						}).then(confirm => {
                            if (!confirm) {
                                obj.dado_modo_entrada = "ramificado";
                                return;
                            }
                            obj.opcoesOcultas = [...obj.opcoesOcultas, ...obj.opcoes.filter(opcao => !opcao.ClientesComplementoOpcaoId)];
                            obj.opcoes.filter(opcao => !!opcao.ClientesComplementoOpcaoId).forEach(opcao => opcao.delete());
                            obj.opcoes = [];
                            this.messageFlow.splice(messages[0].index, this.messageFlow.length);
                            this.addMessageFlowFrom(obj);
						});
                    }
                    if (obj.dado_modo_entrada == "ramificado" && obj.opcoes.length == 0) {
                        obj.opcoes = obj.dado.Opcoes.map((item, index) => {
                            let opcao = new ChatPerguntaOpcao({
                                descricao: item.Opcao,
                                chat_pergunta: obj,
                                posicao: index + 1,
                                proxima_pergunta: obj.proxima_pergunta,
                                ClientesComplementoOpcaoId: item.Id
                            });
                            if (obj.arvore.id != "new") opcao.commitChanges();
                            return opcao;
                        });
                        obj.proxima_pergunta = null;
                        if (obj.arvore.id != "new") obj.commitChanges();
                        this.messageFlow.splice(messages[0].index, this.messageFlow.length);
                        this.addMessageFlowFrom(obj);
                    }
					break;
                case "setor_redirecionamento":
                    messages.filter(item => item.message.object.tipo == "transbordo").forEach(item => {
                        this.messageFlow.splice(item.index + 1, 1, item.message.object.message[1]);
                    });
                    break;
                case "nova-opcao":
                    messages.filter(item => item.message.type == "options").forEach(item => {
                        this.messageFlow.splice(item.index, this.messageFlow.length, item.message.object.message[1]);
                    });
                    break;
            }
        }
        if (obj instanceof ChatPerguntaOpcao) {
            let messages = this.messageFlow.map((message, index) => ({ index: index, message: message })).filter(item => item.message.object == obj.chat_pergunta);
            if (messages.length == 0) return;
            switch (change) {
                case "open": case "close":
                    this.messageFlow.splice(this.messageFlow.indexOf(messages[0].message) + 2, this.messageFlow.length);
                    if (change == "open") this.addMessageFlowFrom(obj);
                    break;
                case "descricao":
                    [...messages.filter(item => item.message.type == "options").map(item => item.message.options.filter(option => option.object == obj)[0])].forEach(option => {
                        if (option) option.label = option.object.descricao;
                    });
                    break;
                case "intencao":
                    {
                        let item = messages[0];
                        let option = this.messageFlow[item.index + 1].options.filter(option => option && option.object == obj)[0];
                        if (!option) return;
                        let object = option.object,
                            refreshOption = {
                                nenhuma: () => {
                                    object.setor_redirecionamento = null;
                                    object.finaliza = false;
                                },
                                transferir: () => {
                                    if (object.setor_redirecionamento == null) object.setor_redirecionamento = {
                                        id: null,
                                        nome: "..."
                                    }
                                    object.proxima_pergunta = null;
                                    object.finaliza = false;
                                },
                                finalizar: () => {
                                    object.finaliza = true;
                                    object.proxima_pergunta = null;
                                    object.setor_redirecionamento = null;
                                },
                            };
                        refreshOption[object.intencao]();
                        if (option.isOpen) {
                            this.messageFlow.splice(item.index + 2, this.messageFlow.length);
                            this.addMessageFlowFrom(object);
                        }
                    }
                    break;
                case "setor_redirecionamento":
                    if (this.messageFlow[messages[0].index + 1].options.filter(option => option.object == obj)[0].isOpen) {
                        this.messageFlow.splice(messages[0].index + 2, this.messageFlow.length);
                        this.addMessageFlowFrom(obj);
                    }
                    break;
            }
        }
    }
    commitChanges() {
        if (this.id == "new") {
            return ArvoreDecisoesServices.post(null, {
                nome: this.nome,
                canais: this.canais
            }).then(data => {
                this.id = data.id;
            });
        }
        return ArvoreDecisoesServices.put(null, {
            id: this.id,
            nome: this.nome,
            pergunta_inicial: this.pergunta_inicial,
            canais: this.canais
        });
    }
    delete() {
        return ArvoreDecisoesServices.remove(this.id);
    }
    static getListed(includeAddInstance) {
        return ArvoreDecisoesServices.get().then(data => {
            let list = data.map(item => new ArvoreDecisoes(item));
            if (includeAddInstance) list.push(new ArvoreDecisoes({
                id: "new",
                nome: ""
            }));
            return list;
        });
    }
}
class ChatPergunta extends Vue {
    constructor(obj) {
        super({
            data: {
                id: null,
                arvore: null,
                descricao: null,
                tipo: null,
                TituloTabulacao: null,
                tabulacao: null,
                dado: null,
                dado_modo_entrada: "simples",
                proxima_pergunta: null,
                setor_redirecionamento: null,
                opcoes: [],
                opcoesOcultas: [],
                addMessage: null,
                isReady: false
            },
            watch: {
                descricao() {
                    if (!this.isReady) return;
                    this.arvore.reportChangeOf(this, "descricao");
                },
                tipo() {
                    if (!this.isReady) return;
                    this.arvore.reportChangeOf(this, "tipo");
                },
                tabulacao() {
                    if (!this.isReady) return;
                    this.arvore.reportChangeOf(this, "tabulacao");
                    this.arvore.reportChangeOf(this, "tipo");
                },
                dado() {
                    if (!this.isReady) return;
                    console.log("dado mudou")
                    this.arvore.reportChangeOf(this, "dado");
                },
                dado_modo_entrada() {
                    if (!this.isReady) return;
                    this.arvore.reportChangeOf(this, "dado_modo_entrada");
                },
                setor_redirecionamento: {
                    handler() {
                        if (!this.isReady) return;
                        this.arvore.reportChangeOf(this, "setor_redirecionamento");
                    },
                    deep: true
                },
                proxima_pergunta() {
                    if (!this.isReady) return;
                    this.arvore.reportChangeOf(this, "proxima_pergunta");
                }
            }
        });
        if (obj == null) return;
        Object.keys(this).forEach(key => {
            if (obj[key] == null) return;
            if (key == "opcoes") {
                this.opcoes = (obj.opcoes ?? []).map(opcao => new ChatPerguntaOpcao({ ...opcao, chat_pergunta: this, tipo: (obj.tipo == 1) ? 1 : null }));
                return;
            }
            this[key] = obj[key];
        });
        if (obj.ClientesConfigComplementoId) {
            ArvoreDecisoesProvider.getClienteCamposComplementares().then(campos => {
                this.dado = campos.find(campo => campo.Id == obj.ClientesConfigComplementoId);
                if (obj.opcoes && obj.opcoes.length > 0) this.dado_modo_entrada = "ramificado";
                setTimeout(() => {
                    this.isReady = true;
                }, 500);
            });
            return;
        }
        setTimeout(() => {
            this.isReady = true;
        }, 500);
    }
    get proximaPergunta() {
        return this.arvore.getPergunta(this.proxima_pergunta);
    }
    get message() {
        return Message.fromChatPergunta(this);
    }
    addOpcao() {
        let opcao = new ChatPerguntaOpcao({
            descricao: `Opção ${this.opcoes.length + 1}`,
            chat_pergunta: this,
            posicao: this.opcoes.length == 0 ? 0 : Math.max(...this.opcoes.map(opcao => opcao.posicao)) + 1
        });
        this.opcoes.push(opcao);
        let callback = () => {
            this.arvore.reportChangeOf(this, "nova-opcao");
            return opcao;
        }
        if (this.arvore.id != "new") return opcao.commitChanges().then(callback);
        return new Promise(resolve => resolve(callback()));
    }
    addOpcoesTabulacaoItens() {
        /*this.opcoesOcultas = // em desenvolvimento*/
        let opcoes = this.tabulacao.TabulacoesItens.map((item, index) => {
            return new ChatPerguntaOpcao({
                descricao: item.titulo ?? `Opção ${index + 1}`,
                TabulacaoItemId: item.id,
                chat_pergunta: this,
                posicao: this.opcoes.length == 0 ? 0 : Math.max(...this.opcoes.map(opcao => opcao.posicao)) + 1
            });
        }).sort()
        opcoes[0].proxima_pergunta = this.proxima_pergunta
        this.opcoes = [...opcoes];
        let callback = () => {
            this.arvore.reportChangeOf(this, "nova-opcao");
            return opcoes.map(opcao => { return opcao });
        }
        if (this.arvore.id != "new") return opcoes.map(opcao => { return opcao.commitChanges().then(callback) });
        return new Promise(resolve => resolve(callback()));
    }
    commitChanges() {
        if (this.id == "deleted") return;
        if (typeof this.id == "symbol") {
            return ArvoreDecisoesServices.postPergunta(this.arvore.id, {
                descricao: this.descricao,
                tipo: this.tipo == "transbordo" ? 0 : this.tipo,
                TabulacaoId: this.tabulacao?.Id,
                dado: typeof this.dado == "number" ? this.dado : 0,
                ClientesConfigComplementoId: typeof this.dado == "object" && this.dado ? this.dado.Id : null,
                setor_redirecionamento: this.setor_redirecionamento
            }).then(data => {
                let symbol = this.id;
                this.id = data.id;
                [
                    ...this.arvore.perguntas.filter(pergunta => pergunta.proxima_pergunta == symbol),
                    ...[].concat(...this.arvore.perguntas.map(pergunta => pergunta.opcoes.filter(opcao => opcao.proxima_pergunta == symbol)))
                ].forEach(item => {
                    item.proxima_pergunta = this.id;
                });
                if (this.arvore.pergunta_inicial == symbol) this.arvore.pergunta_inicial = this.id;
            }).then(async () => await Promise.all([...this.opcoes.map(item => item.commitChanges())]));
        }
        if (!this.tabulacao?.Id && this.tipo === 4) return { message: 'Tabulação não selecionado!', statusError: true };
        return ArvoreDecisoesServices.putPergunta({
            id: this.id,
            descricao: this.descricao,
            tipo: this.tipo == "transbordo" ? 0 : this.tipo,
            TabulacaoId: this.tabulacao?.Id,
            dado: typeof this.dado == "number" ? this.dado : 0,
            ClientesConfigComplementoId: typeof this.dado == "object" && this.dado ? this.dado.Id : null,
            tabulacao: this.tabulacao,
            proxima_pergunta: this.proxima_pergunta,
            setor_redirecionamento: this.setor_redirecionamento
        }).then(async () => await Promise.all([...this.opcoes.map(item => item.commitChanges())]));
    }
    delete() {
        return ArvoreDecisoesServices.removePergunta(this.id);
    }
}
class ChatPerguntaOpcao extends Vue {
    constructor(obj) {
        super({
            data: {
                id: null,
                chat_pergunta: null,
                descricao: null,
                resposta: null,
                intencao: null,
                proxima_pergunta: null,
                setor_redirecionamento: null,
                finaliza: false,
                posicao: null,
                addMessage: null,
                TabulacaoItemId: null,
                ClientesComplementoOpcaoId: null
            },
            watch: {
                descricao() {
                    this.chat_pergunta.arvore.reportChangeOf(this, "descricao");
                },
                intencao() {
                    this.chat_pergunta.arvore.reportChangeOf(this, "intencao");
                },
                setor_redirecionamento: {
                    handler() {
                        this.chat_pergunta.arvore.reportChangeOf(this, "setor_redirecionamento");
                    },
                    deep: true
                },
                proxima_pergunta() {
                    this.chat_pergunta.arvore.reportChangeOf(this, "intencao");
                }
            }
        });
        if (obj == null) return;
        Object.keys(this).forEach(key => {
            if (obj[key] != null) this[key] = obj[key];
        });
        this.intencao = this.setor_redirecionamento ? "transferir" : this.finaliza ? "finalizar" : "nenhuma";
    }
    get proximaPergunta() {
        return this.chat_pergunta.arvore.getPergunta(this.proxima_pergunta);
    }
    get message() {
        return Message.fromChatPerguntaOpcao(this);
    }
    commitChanges() {
        if (this.id == "deleted") return;
        if (!this.id) {
            return ArvoreDecisoesServices.postPerguntaOpcao(this.chat_pergunta.id, {
                descricao: this.descricao,
                resposta: this.resposta,
                proxima_pergunta: this.proxima_pergunta,
                setor_redirecionamento: this.setor_redirecionamento,
                finaliza: this.finaliza,
                posicao: this.posicao,
                TabulacaoItemId: this.TabulacaoItemId,
                ClientesComplementoOpcaoId: this.ClientesComplementoOpcaoId
            }).then(data => {
                this.id = data.id;
            });
        }
        return ArvoreDecisoesServices.putPerguntaOpcao({
            id: this.id,
            descricao: this.descricao,
            resposta: this.resposta,
            proxima_pergunta: this.proxima_pergunta,
            setor_redirecionamento: this.setor_redirecionamento,
            finaliza: this.finaliza,
            posicao: this.posicao,
            TabulacaoItemId: this.TabulacaoItemId,
            ClientesComplementoOpcaoId: this.ClientesComplementoOpcaoId
        });
    }
    delete() {
        if (!this.id) return;
        return ArvoreDecisoesServices.removePerguntaOpcao(this.id);
    }
}

class Message {
    object = null;
    type = null;
    side = null;
    content = null;
    options = [];
    icon = null;
    target = null;
    constructor(obj) {
        if (obj == null) return;
        Object.keys(this).forEach(key => {
            if (obj[key] != null) this[key] = obj[key];
        });
    }
    static fromChatPergunta(chatPergunta) {
        let list = [
            new Message({
                object: chatPergunta,
                type: "message",
                side: "left",
                content: chatPergunta.descricao
            })
        ];
        if (chatPergunta.tipo == 1 && chatPergunta.dado != null && chatPergunta.dado_modo_entrada == "simples") {
            list = [
                ...list,
                new Message({
                    type: "message",
                    side: "right",
                    content: `{${typeof chatPergunta.dado == "number" ? ["", "CLIENTE_RAZAOSOCIAL_NOME", "CLIENTE_NOMEFANTASIA_APELIDO", "CLIENTE_CNPJ_CPF", "CLIENTE_TELEFONE", "CLIENTE_EMAIL", "CLIENTE_CEP", "CLIENTE_ENDERECO", "CLIENTE_ENDERECO_NUMERO", "CLIENTE_ENDERECO_COMPLEMENTO", "CLIENTE_BAIRRO", "CLIENTE_CIDADE", "CLIENTE_ESTADO"][chatPergunta.dado] : `CLIENTE_CAMPO_COMPLEMENTAR_${chatPergunta.dado.Id}`}}`
                }),
                chatPergunta.addMessage ?? new MessageAdd(chatPergunta)
            ];
        } else if (chatPergunta.tipo == 2 || chatPergunta.dado_modo_entrada == "ramificado") {
            let message = new Message({ object: chatPergunta, type: "options" });
            message.options = chatPergunta.opcoes.map(opcao => new MessageOption({
                object: opcao,
                message: message,
                label: opcao.descricao
            }));
            list.push(message);
        } else if (chatPergunta.tipo == 3) {
            list.push(
                new Message({
                    type: "log",
                    content: "Finalização do atendimento",
                    icon: "fa-check-circle"
                })
            );
        } else if (chatPergunta.tipo == 4 && (chatPergunta.tabulacao == 0 || chatPergunta.tabulacao.Tipo < 3)) {
            list = [
                ...list,
                new Message({
                    type: "message",
                    side: "right",
                    content: `${chatPergunta.tabulacao.Titulo ? "{DADOS_TABULACAO}" : "Não Selecionado"}`,
                }),
                chatPergunta.addMessage ?? new MessageAdd(chatPergunta)
            ];
        } else if (chatPergunta.tipo == 4 && chatPergunta.tabulacao.Tipo >= 3) {
            let message = new Message({ object: chatPergunta, type: "options" });
            message.options = chatPergunta.opcoes.map(opcao => new MessageOption({
                object: opcao,
                message: message,
                label: opcao.descricao
            }));
            //let message = new Message({ object: chatPergunta, type: "options" });
            //chatPergunta.opcoes.forEach((opcao, index) => {
            //    message.options.push(
            //        new MessageOption({
            //            object: opcao,
            //            message: message,
            //            label: opcao.descricao
            //        })
            //    );
                /*if (!opcao?.TabulacaoItemId)*//* message.options[index].addOpcaoTabulacaoItens();*/
            //});
            list = [...list, message];
        } else if (chatPergunta.setor_redirecionamento) {
            list.push(
                new Message({
                    type: "log",
                    content: `Transbordo para ${chatPergunta.setor_redirecionamento.nome}`,
                    icon: "fa-headset"
                })
            );
        } else list.push(chatPergunta.addMessage ?? new MessageAdd(chatPergunta));
        return list;
    }
    static fromChatPerguntaOpcao(chatPerguntaOpcao) {
        if (chatPerguntaOpcao.setor_redirecionamento) {
            return [
                new Message({
                    type: "log",
                    content: `Transbordo para ${chatPerguntaOpcao.setor_redirecionamento.nome}`,
                    icon: "fa-headset"
                })
            ];
        } else if (chatPerguntaOpcao.finaliza) {
            return [
                new Message({
                    type: "log",
                    content: "Finalização do atendimento",
                    icon: "fa-check-circle"
                })
            ];
        } else if ((chatPerguntaOpcao.chat_pergunta.tipo == 2 || chatPerguntaOpcao.chat_pergunta.tabulacao?.Tipo >= 3 || chatPerguntaOpcao.chat_pergunta.dado_modo_entrada == "ramificado") && !chatPerguntaOpcao.proximaPergunta) return [
            chatPerguntaOpcao.addMessage ?? new MessageAdd(chatPerguntaOpcao)
        ];
    }
}
class MessageOption {
    object = null;
    message = null;
    label = null;
    isOpen = false;
    constructor(obj) {
        if (obj == null) return;
        Object.keys(this).forEach(key => {
            if (obj[key] != null) this[key] = obj[key];
        });
    }
    open() {
        if (this.isOpen) {
            this.isOpen = false;
            this.object.chat_pergunta.arvore.reportChangeOf(this.object, "close");
            return;
        }
        this.message.options.filter(option => option.isOpen).forEach(option => option.isOpen = false);
        this.isOpen = true;
        this.object.chat_pergunta.arvore.reportChangeOf(this.object, "open");
    }
}
class MessageAdd {
    type = "add";
    side = "left";
    visible = false;
    prevMessageObject = null;
    constructor(prevMessageObject) {
        this.prevMessageObject = prevMessageObject;
        if (prevMessageObject instanceof ArvoreDecisoes) return;
        prevMessageObject.addMessage = this;
    }
}

const ConstructorsNames = {
    ArvoreDecisoes: ArvoreDecisoes.name,
    ChatPergunta: ChatPergunta.name,
    ChatPerguntaOpcao: ChatPerguntaOpcao.name,
    Message: Message.name,
    MessageOption: MessageOption.name,
    MessageAdd: MessageAdd.name
};

export {
    ArvoreDecisoes as default,
    ConstructorsNames
};