/*import moment from "moment";*/
import ArvoreDecisoesServices from "@/assets/js/services/ArvoreDecisoesServices";

const getArvoreDecisoesObjectMap = async () => {
	return {
		nome: {
			label: "Nome da Árvore",
			type: "text"
		},
		canais: {
			label: "Canais relacionados",
			type: "array",
			list: await ArvoreDecisoesServices.getCanaisCompativeis().then(response => response.map(canal => ({
				value: canal.Id,
				label: canal.Nome,
				class: ""
			})))
		}/*,
		disponibilidade: {
			label: "Períodos de disponibilidade",
			type: "matrix:collapsible",
			addable: true,
			model: {
				title: (obj, index) => {
					let descricao = `#${index + 1}${!obj.descricao ? "" : ` (${obj.descricao})`}`;
					let data_inicio = !obj.data_inicio ? null : moment(obj.data_inicio).format("DD/MM/YYYY"),
						data_fim = !obj.data_fim ? null : moment(obj.data_fim).format("DD/MM/YYYY");
					let data_periodo = !data_inicio && !data_fim ? "" : (!data_inicio || !data_fim ? (!data_inicio ? "" : `À partir de ${data_inicio}`) + (!data_fim ? "" : `Até ${data_fim}`) : (data_inicio == data_fim ? `Dia ${data_inicio}` : `De ${data_inicio} até ${data_fim}`));
					let dias_semana = obj.dias_semana.length == 7 ? "Todos os dias" : (obj.dias_semana.join("") == "23456" ? "Dias úteis" : obj.dias_semana.map(dia => ["", "Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"][dia]).join(", ")),
						hora_periodo = !obj.hora_inicio || !obj.hora_fim ? "" : `Das ${obj.hora_inicio} às ${obj.hora_fim}`;
					return [descricao, (obj.disponivel ? "Disponível" : "Indisponível"), data_periodo, dias_semana, hora_periodo].filter(item => item.trim().length > 0).join(" - ");
				},
				properties: {
					descricao: {
						label: "Descrição",
						type: "text",
						default: null,
						nullable: true
					},
					disponivel: {
						label: "Disponível?",
						type: "select",
						default: true,
						options: [
							{ value: true, text: "Sim" },
							{ value: false, text: "Não" }
						]
					},
					intervalo_data: {
						label: "Período de validade",
						type: "span:date",
						from: {
							label: null,
							property: "data_inicio",
							default: null
						},
						to: {
							label: "até",
							property: "data_fim",
							default: null
						}
					},
					dias_semana: {
						label: "Dias da semana",
						type: "array",
						default: [],
						list: [
							{ value: 1, label: "Domingo" },
							{ value: 2, label: "Segunda-feira" },
							{ value: 3, label: "Terça-feira" },
							{ value: 4, label: "Quarta-feira" },
							{ value: 5, label: "Quinta-feira" },
							{ value: 6, label: "Sexta-feira" },
							{ value: 7, label: "Sábado" }
						]
					},
					intervalo_horario: {
						label: "Faixa de horário",
						type: "span:time",
						from: {
							label: null,
							property: "hora_inicio",
							default: null
						},
						to: {
							label: "até",
							property: "hora_fim",
							default: null
						}
					}
				}
			}
		}*/
	};
};
export default getArvoreDecisoesObjectMap;