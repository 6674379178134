import axios from "axios";

let setores = null;
let clienteCamposComplementares = null;

const getSetores = async () => {
	if (!setores) setores = axios.get("api/setor/lista-basica").then(response => response.data.map(setor => ({
		value: setor.Id,
		text: setor.Nome
	})));
	return Promise.resolve(await setores ?? []);
}

const getClienteCamposComplementares = async () => {
	if (!clienteCamposComplementares) clienteCamposComplementares = axios.get("api/v2/clientes/campos-capturaveis").then(response => response.data);
	return Promise.resolve(await clienteCamposComplementares ?? []);
}

const clear = () => {
	setores = null;
	clienteCamposComplementares = null;
}

export default {
	getSetores,
	getClienteCamposComplementares,
	clear
}