import ArvoreDecisoesProvider from "@/assets/js/providers/arvore-decisoes";
import TabulacaoServices from "@/assets/js/services/TabulacaoServices";

let tabulacoes = null;

const getTabulacoes = async () => {
    tabulacoes = await TabulacaoServices.getTabulacaoBasico().then(response => response.map(tabulacao => ({
        value: tabulacao.value,
        text: tabulacao.text
    })))
    return tabulacoes;
}

const getChatPerguntaObjectMap = async () => {
    return {
        descricao: {
            label: "Conteúdo",
            type: "html",
            varsList: [
                { value: "{CODIGO_ATENDIMENTO}", label: "Código do Atendimento" },
                { value: "{CLIENTE_RAZAOSOCIAL_NOME}", label: "Razão Social / Nome" },
                { value: "{CLIENTE_NOMEFANTASIA_APELIDO}", label: "Nome Fantasia / Apelido" },
                { value: "{CLIENTE_CNPJ_CPF}", label: "CPF / CNPJ" },
                { value: "{CLIENTE_TELEFONE}", label: "Telefone" },
                { value: "{CLIENTE_EMAIL}", label: "E-mail" },
                { value: "{CLIENTE_CEP}", label: "CEP" },
                { value: "{CLIENTE_ENDERECO}", label: "Endereço" },
                { value: "{CLIENTE_ENDERECO_NUMERO}", label: "Número" },
                { value: "{CLIENTE_ENDERECO_COMPLEMENTO}", label: "Complemento" },
                { value: "{CLIENTE_BAIRRO}", label: "Bairro" },
                { value: "{CLIENTE_CIDADE}", label: "Cidade" },
                { value: "{CLIENTE_ESTADO}", label: "Estado" },
                ...(await ArvoreDecisoesProvider.getClienteCamposComplementares()).map(campo => ({ value: `{CLIENTE_CAMPO_COMPLEMENTAR_${campo.Id}}`, label: campo.Campo })),
                { value: "{DADOS_TABULACAO}", label: "Dados Tabulação" }
            ],
        },
        tipo: {
            label: "Intenção",
            type: "select",
            options: [
                { value: 0, text: "Nenhuma" },
                { value: 1, text: "Obter dado do cliente" },
                { value: 2, text: "Exibir menu de opções" },
                { value: "transbordo", text: "Transferir para um setor" },
                { value: 3, text: "Finalizar atendimento" },
                { value: 4, text: "Obter tabulação" }
            ]
        },
        tabulacao: {
            label: "Obter tabulação",
            type: "select",
            options: await getTabulacoes(),
            visible: object => object.tipo == 4
        },
        dado: {
            label: "Obter dado do cliente",
            type: "select",
            options: [
                { value: 1, text: "Razão Social/Nome" },
                { value: 2, text: "Nome Fantasia/Apelido" },
                { value: 3, text: "CNPJ/CPF" },
                { value: 4, text: "Telefone" },
                { value: 5, text: "E-mail" },
                { value: 6, text: "CEP" },
                { value: 7, text: "Endereço" },
                { value: 8, text: "Número" },
                { value: 9, text: "Complemento" },
                { value: 10, text: "Bairro" },
                { value: 11, text: "Cidade" },
                { value: 12, text: "Estado" },
                ...(await ArvoreDecisoesProvider.getClienteCamposComplementares()).map(campo => ({
                    value: campo,
                    text: campo.Campo
                }))
            ],
            visible: object => object.tipo == 1
        },
        dado_modo_entrada: {
            label: "Modo de entrada de dados",
            type: "select",
            options: [
                { value: "simples", text: "Entrada simples" },
                { value: "ramificado", text: "Criar ramificações..." }
            ],
            visible: object => object.dado != null && typeof object.dado == "object" && object.dado.Opcoes?.length > 0
        },
        setor_redirecionamento: {
            label: "Transferir para o setor",
            type: "select.pair",
            valueAt: "id",
            textAt: "nome",
            options: await ArvoreDecisoesProvider.getSetores(),
            visible: object => object.tipo == "transbordo"
        }
    };
};

const getChatPerguntaOpcaoObjectMap = async () => {
    return {
        descricao: {
            label: "Descrição",
            type: "text"
        },
        resposta: {
            label: "Texto de resposta",
            type: "text"
        },
        intencao: {
            label: "Intenção",
            type: "select",
            options: [
                { value: "nenhuma", text: "Nenhuma" },
                { value: "transferir", text: "Transferir para um setor" },
                { value: "finalizar", text: "Finalizar atendimento" }
            ]
        },
        ClientesComplementoOpcaoId: {
            label: object => `Vínculo da opção com o campo de ${JSON.stringify(object.chat_pergunta.dado.Campo)}`,
            type: "select",
            options: object => {
                return [
                    { value: null, text: "Sem vínculo" },
                    ...object.chat_pergunta.dado.Opcoes.map(opcao => ({
                        value: opcao.Id,
                        text: opcao.Opcao
                    }))
                ];
            },
            visible: object => object.dado != null && typeof object.chat_pergunta.dado == "object" && object.chat_pergunta.dado.Opcoes.length > 0
        },
        setor_redirecionamento: {
            label: "Transferir para o setor",
            type: "select.pair",
            valueAt: "id",
            textAt: "nome",
            options: await ArvoreDecisoesProvider.getSetores(),
            visible: object => object.intencao == "transferir"
        }
    };
};

export default {
    getChatPerguntaObjectMap,
    getChatPerguntaOpcaoObjectMap
};